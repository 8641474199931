import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { apiGetCategories, apiAddApp } from "../../APIService";

import './AddApplication.css'

const AddApplication = (props) => {
  const [appName, setAppName] = useState(props.selectedApp == null ? '' : props.selectedApp.name)
  const [appDev, setAppDev] = useState(props.selectedApp == null ? '' : props.selectedApp.developer)
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState(props.selectedApp == null ? '' : props.selectedApp.category)
  const [appDesc, setAppDesc] = useState(props.selectedApp == null ? '' : props.selectedApp.desc)
  const [appIcon, setAppIcon] = useState(null)
  const [iconUrl, setIconUrl] = useState(props.selectedApp == null ? '' : props.selectedApp.icon)
  const [appNameError, setAppNameError] = useState('')
  const [appDevError, setAppDevError] = useState('')
  const [appCatError, setAppCatError] = useState('')
  
  
  const toast = useRef(props.refToast)
  const imageInputRef = useRef(null);

  useEffect(() => {
    getCategories()
  }, []); 

  useEffect(() => {
    if (categories.length > 0) {
      if (props.selectedApp != null) {
        let cat = categories.find(obj => obj.name === props.selectedApp.category);
        if (cat != null && cat !== undefined) {
          setCategory(cat)
        }
      }
      else {
        setCategory(categories[0])
      }
    }
  }, [categories, props.selectedApp]); 


  const getCategories = () => {
    apiGetCategories().then(response => {
      setCategories(response)
    })
  }

  const onAppNameChange = (e) => {
    setAppName(e.target.value)
    setAppNameError('')
  }

  const onAddDevChange = (e) => {
    setAppDev(e.target.value)
    setAppDevError('')
  }

  const onCategotyChange = (e) => {
    setCategory(e.value);
  }

  const footer = () => {
    return (
      <div>
        <Button label="Отмена" icon="pi pi-times" onClick={onHide} className="p-button-text" />
        <Button label='Сохранить' icon="pi pi-check" onClick={onAdd} autoFocus />
      </div>
    );
  }

  async function onAdd() {
    if (!validate()) {
      return;
    }

    let isAdd = (props.selectedApp == null)

    const formData = new FormData();
    if (appIcon != null) {
      formData.append("image", appIcon);
    }
      
    if (!isAdd) {
      formData.append('app_id', props.selectedApp.app_id);
    }

    formData.append('name', appName);
    formData.append('dev', appDev);
    formData.append('category', category.category_id);
    formData.append('description', appDesc)

    const result = await apiAddApp(formData)
    if (result) {
      toast.current.current.show({ severity: 'success', summary: 'Успешно', detail: `${isAdd ? 'Приложение добавлено' : 'Изменения сохранены'}`, life: 3000 })
    } else {
      toast.current.current.show({ severity: 'error', summary: 'Ошибка', detail: `Изменения не сохранены`, life: 3000 })
    }

   props.onRefresh()
  }


  const iconBtnClick = () => {
    imageInputRef.current.click();
  }

  const onIconChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile !== undefined) {
      setAppIcon(selectedFile)
      setIconUrl(URL.createObjectURL(selectedFile))
    }
  };


  const onHide = () => {
    props.onHide()
  }

  const validate = () => {
    if (appName.length <= 0) {
      setAppNameError('Заполните название приложения')
      return false;
    } else if (appName.length > 50) {
      setAppNameError('Название приложения слишком длинное')
      return false;
    }

    if (appDev.length <= 0) {
      setAppDevError('Заполните разработчика приложения')
      return false;
    } else if (appDev.length > 50) {
      setAppDevError('Наименование разработчика слишком длинное')
      return false;
    }

    if (!category) {
      setAppCatError('Выберите категорию приложения');
      return false;
    }
    
    return true;
  }


  return (
    <div>
      <Dialog className='add-dialog' headerClassName='add-dialog-header' footerClassName='add-dialog-footer' 
        header={props.selectedApp == null ? 'Добавить приложение' : 'Редактировать приложение'} footer={footer} visible={props.visible} onHide={onHide}>
        <div className="add-container">
          <div className="field-add">
            <label htmlFor="appName">Название приложения</label>
            <InputText id="appName" value={appName} onChange={onAppNameChange} />
            <small id="appName-error" className="p-error">{appNameError}</small>
          </div>
          <span className="field-add">
            <label htmlFor="appDev">Разработчик</label>
            <InputText id="appDev" value={appDev} onChange={onAddDevChange} />
            <small id="appDev-error" className="p-error">{appDevError}</small>
          </span>
          <span className="field-add">
            <label htmlFor="appDev">Категория</label>
            <Dropdown id="appCategory" value={category} options={categories} optionLabel='name' onChange={onCategotyChange} />
            <small id="appDev-error" className="p-error">{appCatError}</small>
          </span>
          <span className="field-add">
            <label htmlFor="appDev">Иконка</label>
            <span className='add-app-icon'>
              <Image imageClassName='add-app-image' src={iconUrl}></Image>
              <Button onClick={iconBtnClick} icon={`pi pi-fw ${iconUrl === '' ? 'pi-plus' : 'pi-pencil'}`} label={iconUrl === '' ? 'Добавить' : 'Изменить'}></Button>
              <input type="file" ref={imageInputRef} accept="image/*" style={{ display: 'none' }} onChange={onIconChange}/>
            </span>              
            <small id="appDev-error" className="p-error"></small>
          </span>
          <span className="field-add">
            <label htmlFor="appDescription">Описание</label>
            <InputTextarea id="appDescription" value={appDesc} onChange={(e) => setAppDesc(e.target.value)} />
          </span>
        </div>
      </Dialog>
    </div>      
  )
};

export default AddApplication